//https://accelerometerdemo.netlify.app/
//https://developer.mozilla.org/en-US/docs/Web/API/Touch_events


// window.addEventListener("pageshow", (event) => {
//   if (event.persisted) {
//     alert("The page was cached by the browser");
//   } else {
//     alert("The page was NOT cached by the browser");
//   }
// });


//alert('darskmode');



// function getRandomIntInclusive(min, max) {
//   min = Math.ceil(min);
//   max = Math.floor(max);
//   return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
// }


const Psize = '4.16%';
var increase = Math.PI / 100;




// if you're using a bundler, first import:
import Headroom from "headroom.js";
// grab an element
var myElement = document.querySelector(".hektor-nav-wr");
// construct an instance of Headroom, passing the element
var headroom = new Headroom(myElement);
// initialise
headroom.init();



//import { DisplayLabel } from './components/DisplayLabel';



//import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// import 'dark-mode-switch';
//import '/css/test.scss';

//alert('hoezo kappen hij doet yolo!!!');
//Import our custom CSS 
//import '../../css/src/main.scss';

//import './dark-mode-switch.js';


import Swiper from 'swiper/bundle';
//import Swiper from 'swiper';
//* import the Swiper styles
// import 'swiper/css';

// // import styles bundle
import 'swiper/css/bundle';



let swiper;
function innitSwiper() {
  
   swiper = new Swiper('.swiper', {
    // Optional parameters
    //direction: 'vertical',
    loop: false,
    autoHeight: true,
    //wrapperClass: 'wp-block-gallery',
    //slideClass: 'wp-block-image',
    //containerModifierClass:'wp-block-gallery',
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3
    },
     keyboard: {
       enabled: true, // Enable keyboard control
     },
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    //   draggable: true
    // },
    on: {
      init: function () {
        // Swiper is ready
        
        //console.log('Swiper is ready');
        innitBackdrop();
      }
    }
  });


  // Function to go to a specific slide
  

  // Example usage:
  //goToSlide(5); // Go to slide index 2
  //innitBackdrop();
}

document.addEventListener('DOMContentLoaded', function () {
  innitSwiper();
});


function innitBackdrop() {
 
    var clickableDivs = document.querySelectorAll('.gid-item');
    var closeBut = document.querySelector('a.close');
  var swiperGallery = document.querySelector('.custom-gallery-wrapper');
  

  function closeSwiperGallery() {
    swiperGallery.classList.toggle('d-none');
  }
    // register close button to close overlay/backdrop with gallary
    closeBut.addEventListener('click', function () {
      closeSwiperGallery();
    }
  );



    clickableDivs.forEach(function (div) {
      div.addEventListener('click', function () {
        swiperGallery.classList.toggle('d-none');
        var index = this.dataset.index;
        swiper.slideTo(index);
        //swiper.slideTo(index); 
       //swiperGallery.classList.toggle('d-none');
      });
    });
    document.addEventListener('keydown', function (event) {
    // Check if the pressed key is the "Escape" key (key code 27)
    if (event.key === "Escape" || event.keyCode === 27) {
      // Perform an action here, such as closing the modal
      //check if gallery is open
      if (swiperGallery.classList.contains('d-none')) {
      } else {
        closeSwiperGallery();
      }
        
     
    }
  });
  
}

/* gallery go to index swiper */

// myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
//   alert('boe');
// })

// barba.init({
//   timeout: 10000,
//   requestError: (trigger, action, url, response) => {
//     // go to a custom 404 page if the user click on a link that return a 404 response status
//     if (action === 'click' && response.status && response.status === 404) {
//       barba.go('/404');
//     }

//     // prevent Barba from redirecting the user to the requested URL
//     // this is equivalent to e.preventDefault() in this context
//     return false;
//   },
//   transitions: [{
    
//     sync: true,
//     name: 'opacity-transition-yolo',
//     leave(data) {
//       return gsap.to(data.current.container, {x: 0, y:0, duration: .25, opacity:0});
//     },
//     enter(data) {
//       return gsap.from(data.next.container, {x: 0, y:0, duration: .4, opacity:0.4});
//     }
//   }]
// });


// barba.hooks.afterEnter((data) => {
//   innitSwiper();
//   //console.log(data.next.namespace);
//   //alert('console.log(data.next.namespace);');
//   var Pnav = document.getElementsByClassName('single-post-nav-wr')[0];
//   var PnavHtml = Pnav.innerHTML;
//   var Cnav = document.getElementsByClassName('outer-barba')[0];
//   Cnav.innerHTML = '';
//   Pnav.innerHTML = '';
//   Pnav.remove();
//   // console.log(Pnav);
//   // console.log('!');
//   Cnav.innerHTML = PnavHtml;
  

 

// });

// var Flickity = require('flickity');
// require('flickity-imagesloaded');
// require('flickity-fullscreen');

//Main.init();


document.addEventListener("DOMContentLoaded", function() {
  
  

  document.querySelectorAll("#wpadminbar a").forEach(item=>item.setAttribute('data-barba-prevent','self'));
  var myOffcanvas = document.getElementById('navbarOffcanvasMd')
  var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

  
 
  
  var windowWidth = window.innerWidth; 
  //console.log(windowWidth);
  if(windowWidth <= 767){
  
    const cbox = document.querySelectorAll(".menu-item");

    for (let i = 0; i < cbox.length; i++) {
      cbox[i].addEventListener("mousedown", function(){
        bsOffcanvas.toggle();
      });
    }

  }

  // var menuitem = document.querySelectorAll('.menu-item');
  // menuitem.addEventListener("mousedown", function(){
  //   alert('!');
  // });
  
  const highlightedItems = document.querySelectorAll(".portfolio-item");
 
  var st = setTimeout(function(){
    highlightedItems.forEach((userItem) => {
      userItem.classList.remove("hide-car");
    });
  },500);
});
const style = 'background-color: #b5bcda; color: #2d2d8b; display:inline-block; padding:1rem; border: 5px solid #26349f; font-size: 2em;'
console.log("%cI will Always ❤️ You 🎻", style);
//console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  //console.log('Looks like we are in development mode');
}else{
  console.log('Looks like we are in production mode');
}
 